import React from 'react'
export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1627.15'
    height='803.298'
    viewBox='0 0 1627.15 803.298'>
    <defs>
      <linearGradient
        id='linear-gradient'
        x1='0.031'
        y1='-0.072'
        x2='0.938'
        y2='1.224'
        gradientUnits='objectBoundingBox'>
        <stop offset='0' stopColor='#214a07' stopOpacity='0' />
        <stop offset='0.127' stopColor='#2d580f' stopOpacity='0.145' />
        <stop offset='0.43' stopColor='#72ab3f' />
        <stop offset='0.705' stopColor='#30570c' stopOpacity='0' />
        <stop offset='1' stopColor='#244803' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='linear-gradient-2'
        x1='0.031'
        y1='1.072'
        x2='0.938'
        y2='-0.224'
        gradientUnits='objectBoundingBox'>
        <stop offset='0' stopColor='#214a07' stopOpacity='0' />
        <stop offset='0.127' stopColor='#2d580f' stopOpacity='0.145' />
        <stop offset='0.43' stopColor='#72ab3f' />
        <stop offset='0.705' stopColor='#30570c' stopOpacity='0' />
        <stop offset='1' stopColor='#244803' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='linear-gradient-3'
        x1='0.969'
        y1='1.072'
        x2='0.062'
        y2='-0.224'
        gradientUnits='objectBoundingBox'>
        <stop offset='0' stopColor='#214a07' stopOpacity='0' />
        <stop offset='0.108' stopColor='#2d580f' stopOpacity='0' />
        <stop offset='0.229' stopColor='#72ab3f' />
        <stop offset='0.531' stopColor='#2b5008' stopOpacity='0' />
        <stop offset='1' stopColor='#244803' stopOpacity='0' />
      </linearGradient>
    </defs>
    <g id='Path_52' dataname='Path 52' transform='translate(-4938.211 -11946)'>
      <path
        id='Path_52-2'
        dataname='Path 52'
        d='M52.519,218.91l-3.039,1.816L283.941,609.013a92.2,92.2,0,0,0,34.642,31.3,100.778,100.778,0,0,0,46.663,11.4H726.831c26.4,0,59.049,18.237,72.794,40.721l13.845,22.62L966.179,964.542c14.316,23.381,48.319,42.408,75.8,42.408h133.286v-3.518H1041.982c-26.39,0-59.041-18.275-72.787-40.728L802.635,690.6c-14.308-23.328-48.319-42.37-75.8-42.37H365.261c-31.914,0-61.906-15.721-78.265-41.032Z'
        transform='translate(4888.731 11742.349)'
        opacity='0.61'
        fill='url(#linear-gradient)'
      />
      <path
        id='Path_51'
        dataname='Path 51'
        d='M1256.751,432.344H1075.107c-32.263,0-62.05-15.813-77.733-41.275L838.564,133.363c-14.87-24.133-43.16-39.125-73.835-39.125H654.23V90.72H764.759c31.914,0,61.336,15.63,76.844,40.8l158.81,257.713c15.045,24.422,43.677,39.6,74.724,39.6h181.644Z'
        transform='translate(4319.891 12042.935)'
        opacity='0.28'
        fill='url(#linear-gradient-2)'
      />
      <path
        id='Path_50'
        dataname='Path 50'
        d='M664.21,529.278h390.171c24.551,0,47.415-11.975,59.664-31.253L1420.868,1.846l-3-1.846L1111.051,496.187c-11.6,18.237-33.32,29.589-56.67,29.589H664.21Z'
        transform='translate(5144.494 11946)'
        opacity='0.47'
        fill='url(#linear-gradient-3)'
      />
    </g>
  </svg>
)
