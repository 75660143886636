import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { s } from '../../style'

const content = {
  title: {
    pl: 'Finansowanie kredyty/leasingi',
    en: 'Financing loans/leases',
    zh: '融资贷款/租赁',
  },
  desc: {
    pl: `Poznaj naszych ekspertów.`,
    en: ``,
  },
}

const ProductFinancing = () => {
  const { lang } = useLangContext()
  const query = useStaticQuery(graphql`
    {
      pko: file(
        absolutePath: { regex: "/images/product/financing/pkobp.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [PNG]
          )
        }
      }
      pekao: file(
        absolutePath: { regex: "/images/product/financing/pekao.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [PNG]
          )
        }
      }
      santander: file(
        absolutePath: { regex: "/images/product/financing/santander.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [PNG]
          )
        }
      }
      ing: file(absolutePath: { regex: "/images/product/financing/ing.png/" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [PNG]
          )
        }
      }
    }
  `)

  const logotypes = [
    {
      alt: 'Bank PKO BP',
      image: query.pko,
    },
    {
      alt: 'Bank PEKAO SA',
      image: query.pekao,
    },
    {
      alt: 'Santander Bank Śląski',
      image: query.santander,
    },
    {
      alt: 'Bank ING',
      image: query.ing,
    },
  ]

  return (
    <>
      <section css={sSectionWrapper}>
        <Headline h={2} title={content.title[lang]} large gradient />
        <Swiper
          // install Swiper modules
          modules={[Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={2}
          slidesPerGroup={1}
          autoplay={{delay: 5000}}
          pagination={{ enabled: true, clickable: true }}
          breakpoints={{
            767: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              pagination: {
                enabled: true,
              },
            },
            1199: {
              slidesPerView: 4,
              slidesPerGroup: 1,
              pagination: {
                enabled: true,
              },
            },
          }}
          css={[productRelatedProducts]}
          style={{
            '--swiper-pagination-color': '#20214f',
          }}>
          {logotypes.map((item, index) => (
            <SwiperSlide key={index} >
              <div css={logoWrapper}>
                <GatsbyImage
                  alt={item.alt}
                  image={getImage(item.image)}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  )
}

const sSectionWrapper = {
  maxWidth: 1500,
  margin: '0 auto',
  padding: '3rem 4rem 5rem',
  [s.md_1024]: {
    padding: '3rem 1rem 3rem',
  },
}

const productRelatedProducts = {
  marginTop: '3rem',
}

const logoWrapper = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > div': {
    maxWidth: '200px',
    margin: '0 auto',
  }
}

export default ProductFinancing
