// extracted by mini-css-extract-plugin
export var addToCartStyle = "product-page-module--addToCartStyle--8f946";
export var breadcrumb = "product-page-module--breadcrumb--7b2bb";
export var labelFont = "product-page-module--labelFont--623be";
export var locationProducts = "product-page-module--locationProducts--a229a";
export var locationProductsHeadline = "product-page-module--locationProductsHeadline--d7901";
export var locationProductsWrapper = "product-page-module--locationProductsWrapper--67610";
export var metaSection = "product-page-module--metaSection--74929";
export var noImagePreview = "product-page-module--noImagePreview--ce61d";
export var optionsWrapper = "product-page-module--optionsWrapper--0badb";
export var priceOmnibusWrapper = "product-page-module--priceOmnibusWrapper--f8490";
export var priceValue = "product-page-module--priceValue--04f42";
export var priceWrapper = "product-page-module--priceWrapper--9d255";
export var pricesWrapper = "product-page-module--pricesWrapper--8fccf";
export var productBox = "product-page-module--productBox--7af8c";
export var productButtonWrapper = "product-page-module--productButtonWrapper--67c5c";
export var productCardHeadlineWrapper = "product-page-module--productCardHeadlineWrapper--ff9b3";
export var productDescSection = "product-page-module--productDescSection--ae4a2";
export var productDescription = "product-page-module--productDescription--84aa1";
export var productImageList = "product-page-module--productImageList--4f474";
export var productImageListItem = "product-page-module--productImageListItem--04f28";
export var productImageWrapper = "product-page-module--productImageWrapper--32b09";
export var productImagesSlider = "product-page-module--productImagesSlider--a765c";
export var productImagesSliderItem = "product-page-module--productImagesSliderItem--82f33";
export var productImagesSliderThumbs = "product-page-module--productImagesSliderThumbs--9cad1";
export var productImagesSliderThumbsItem = "product-page-module--productImagesSliderThumbsItem--56d30";
export var productImagesSliderThumbsItemActive = "product-page-module--productImagesSliderThumbsItemActive--949f7";
export var productImagesSliderWrapper = "product-page-module--productImagesSliderWrapper--64e24";
export var productRelatedProductsWrapper = "product-page-module--productRelatedProductsWrapper--e0389";
export var productSection = "product-page-module--productSection--c21b3";
export var sHtml2 = "product-page-module--sHtml2--e3a2a";
export var scrollForMore = "product-page-module--scrollForMore--5fbff";
export var selectVariant = "product-page-module--selectVariant--dffdb";
export var swiperButtonPrev = "product-page-module--swiper-button-prev--2f750";
export var swiperPaginationBulletActive = "product-page-module--swiper-pagination-bullet-active--cf8c3";
export var swiperSlide = "product-page-module--swiper-slide--77722";
export var tagList = "product-page-module--tagList--c2bfc";
export var visuallyHidden = "product-page-module--visuallyHidden--3d32b";