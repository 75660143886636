import React from 'react'
import { useMatchMedia } from '../../utils/useMatchMedia'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import Headline from '../../components/Headline'
import { useLangContext } from '../../context/lang.context'
import { s, colors, alpha } from '../../style'
import OrderStep from '../../components/OrderStep'

import lines1 from '../../assets/lines1.svg'
import lines2 from '../../assets/lines2.svg'
import lines3 from '../../assets/lines3.svg'
import lines4 from '../../assets/lines4.svg'

const content = {
  title: {
    pl: 'Proces realizacji zamówień',
    en: 'Order fulfillment process',
    zh: '订单处理流程',
  },
  desc: {
    pl: `Poznaj naszych ekspertów.`,
    en: ``,
  },
}

const ProductSteps = ({ steps }) => {
  const { lang } = useLangContext()
  const isDesktopResolution = useMatchMedia('(min-width:960px)', true)
  const { index, stepName, stepDesc, stepImage } = steps

  return (
    <div css={sRelative}>
      {/* <Section top id={id}> */}
      <section css={sSectionWrapper}>
        <div css={sLines2}>
          {lines2}
          <div css={sLines2.hidder} />
        </div>
        <Headline h={2} title={content.title[lang]} large gradient />
        <div css={sLines1}>
          {lines1}
          <div css={sLines1.hidder} />
        </div>
        <div css={sLines4}>
          {lines4}
          <div css={sLines4.hidder} />
        </div>
        <div css={sStages}>
          {isDesktopResolution &&
            steps
            .filter((step) => step.stepName != null)
            .map((step, index) => (
              <OrderStep
                key={index}
                id={index}
                data={step}
                image={step.stepImage.gatsbyImageData}
                number={`0` + (index + 1)}
              />
            ))}
          {!isDesktopResolution && (
            <Swiper
              // install Swiper modules
              modules={[Pagination, Autoplay]}
              spaceBetween={30}
              slidesPerView={1}
              slidesPerGroup={1}
              autoplay={{delay: 5000}}
              pagination={{ enabled: true, clickable: true }}
              style={{
                '--swiper-pagination-color': '#20214f',
              }}>
              {steps
                .filter((step) => step.stepName != null)
                .map((step, index) => (
                <SwiperSlide key={index}>
                  <OrderStep
                    key={index}
                    id={index}
                    data={step}
                    image={step.stepImage.gatsbyImageData}
                    number={`0` + (index + 1)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        <div css={sLines3}>
          {lines3}
          <div css={sLines3.hidder} />
        </div>
      </section>
    </div>
  )
}

const sRelative = {
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 20,
  overflow: 'hidden',
}

const sSectionWrapper = {
  maxWidth: 1500,
  margin: '0 auto',
  padding: '3rem 4rem',
  [s.md_1024]: {
    padding: '2rem 1rem 1rem',
  },
}

const sLines1 = {
  zIndex: -1,
  pointerEvents: 'none',
  position: 'absolute',
  // display: 'inline',
  display: 'none',
  [s.lg]: { left: -100, top: 380, transform: 'scale(0.8)' },
  [s.sm_down]: { left: 0, top: 3550, transform: 'scale(1)', display: 'none' },
}

const sLines2 = {
  zIndex: 0,
  pointerEvents: 'none',
  position: 'absolute',
  [s.lg]: { left: 900, top: -300, transform: 'scale(1)', display: 'inline', display: 'none', },
  [s.xlg]: {
    transform: 'scale(0.80)',
    left: 750,
    top: -80,
    display: 'inline',
  },
  [s.md_only]: {
    left: 500,
    top: -125,
    transform: 'scale(0.7)',
    // display: 'inline',
    display: 'none',
  },
  [s.sm_down]: {
    left: 0,
    top: 700,
    transform: 'scale(1.25)',
    display: 'none',
  },
  hidder: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: `linear-gradient(#ffffff00, ${colors.white.concat(
      alpha[100]
    )} 85%)`,
  },
}

const sStages = {
  position: 'relative',
  zIndex: 20,
  marginTop: '3rem',
}

const sLines3 = {
  zIndex: -1,
  pointerEvents: 'none',
  position: 'absolute',
  [s.lg]: { left: -100, top: 1300, transform: 'scale(1)', display: 'inline', display: 'none', },
  [s.xlg]: {
    transform: 'scale(0.70)',
    left: -300,
    top: 1600,
    // display: 'inline',
    display: 'none',
  },
  [s.sm_down]: { display: 'none' },
}

const sLines4 = {
  zIndex: -2,
  pointerEvents: 'none',
  position: 'absolute',
  [s.lg]: { left: -181, top: 2725, transform: 'scale(0.85)' },

  [s.xlg]: {
    transform: 'scale(0.65)',
    left: -258,
    top: 2725,
  },
  [s.md_only]: { left: -300, top: 3055, transform: 'scale(0.5)' },
  [s.sm_down]: {
    left: -300,
    top: -235,
    transform: 'scale(0.60)',
    display: 'none',
  },
  [s.xs]: {
    left: -337,
    top: -500,
    transform: 'scale(0.40)',
  },
  hidder: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    height: '50%',
    background: `linear-gradient(#ffffff00, ${colors.white.concat(
      alpha[100]
    )} 85%)`,
  },
}

export default ProductSteps
