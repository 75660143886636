import React from 'react'
import { useLangContext } from '../context/lang.context'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import alt from '../content/alt'
import { sGatsbyImage, s, colors, alpha, sTextGradient } from '../style'

const OrderStep = ({ id, data, image, stage, number }) => {
  const { lang } = useLangContext()
  return (
    <div css={sOuterContainer}>
      <div css={sContainer}>
        <div
          css={[
            sText,
            id % 2 === 1
              ? { [s.md]: { order: 2, paddingLeft: '4rem' } }
              : { [s.md]: { paddingRight: '4rem' } },
            sGridItem,
          ]}>
          <div className='stage-headline'>
            <span className='stage-number'>{number}</span>
            <div css={{ display: 'flex', flexDirection: 'column' }}>
              <span className='stage-subtitle'>Etap</span>
              <span className='stage-title'>{data.stepName}</span>
            </div>
          </div>

          {data.stepDesc && (
            <div
              className='stage-body'
              dangerouslySetInnerHTML={{ __html: data.stepDesc.childMarkdownRemark.html }}
            />
          )}
        </div>
        <div css={sGridItem}>
          <div
            css={{
              left: 0,
              top: 0,
              overflow: 'hidden',
              position: 'relative',
              [s.xs]: {
                height: '0',
                paddingBottom: '56.25%',
                borderRadius: '1rem',
              },
              [s.sm]: {
                width: '100%',
                height: 400,
                borderRadius: '2rem',
                boxShadow: `-8px 16px 32px ${colors.greyDarker.concat(
                  alpha[16]
                )}`,
              },
            }}>
            <GatsbyImage
              image={getImage(image)}
              alt={data.stepName + alt}
              style={{
                ...sGatsbyImage,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const sGridItem = {
  flexBasis: '100%',
  maxWidth: '100%',
  [s.md]: {
    flexBasis: '50%',
    maxWidth: '50%',
  },
}

const sOuterContainer = {
  // width: '100vw',
  // maxWidth: 1500,
  // margin: '0 auto',
}

const sContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  zIndex: 30,
  [s.md]: {
    marginBottom: '6rem',
  },
}

const sText = {
  '.stage-headline': {
    display: 'flex',
    alignItems: 'flex-end',
    [s.xs]: { margin: '2rem 0' },
    [s.sm]: { margin: '4rem 0' },
  },
  '.stage-number': {
    opacity: 0.08,
    fontWeight: 700,
    lineHeight: '0.9em',
    [s.xs]: {
      fontSize: 96,
      letterSpacing: '-0.1em',
    },
    [s.sm]: {
      fontSize: 128,
      letterSpacing: '-0.075em',
    },
    marginRight: '1rem',
  },
  '.stage-subtitle': {
    opacity: 0.3,
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '0.25em',
    [s.xs]: {
      fontSize: '0.75rem',
    },
    [s.sm]: {
      fontSize: '1rem',
    },
  },
  '.stage-title': {
    fontWeight: 800,
    lineHeight: '1.2em',
    fontSize: '2rem',
    letterSpacing: '-0.1em',
    [s.lg_1200]: {
      fontSize: '3rem',
    },
  },

  '.stage-body': {
    opacity: 0.6,
    lineHeight: 1.618 + 'em',
    [s.xs]: {
      fontSize: '0.75rem',
    },
    [s.sm]: {
      fontSize: '0.875rem',
    },
    [s.sm_down]: {
      marginBottom: '3rem',
    },
    p: {
      marginBottom: '1rem',
    },
    ul: { paddingInlineStart: 0, marginInlineStart: '1rem' },
    li: {
      paddingBottom: '0.5rem',
    },
  },
}

export default OrderStep
