import React from 'react'
import { useLangContext } from '../../context/lang.context'
import GoogleMapReact from 'google-map-react'
import LocationOmida from '../../assets/LocationOmida'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import { graphql, useStaticQuery } from 'gatsby'
import { colors, s } from '../../style'

const content = {
  title: {
    pl: 'Mapa Depotów Kontenerowych',
    en: 'Our locations',
    zh: '地点',
  },
  desc: {
    pl: `Sprzedajemy kontenery w całej Europie. Sprawdź, mapę dostępnych kontenerów`,
    en: `Where you will find our CONTAINERS`,
    zh: `集装箱所在位置`,
  },
}

const ProductMap = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      children: allContentfulDepot(
        sort: { fields: city }
        filter: { city: { ne: null } }
      ) {
        nodes {
          city
          slug
          lang {
            slug
          }
          coords {
            lat
            lon
          }
          address
          noCreatePage
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
    }
  `)

  const test = () => {
    if(window.innerWidth < '767') {
      return 4;
    } else {
      return 5;
    }
  }

  const links = query.children.nodes
    .filter((node) => node.lang.slug === lang)
    .map((node) => ({
      link: node.noCreatePage ? node.slug.slice(0, -2) : node.slug,
      title: node.city,
      image: node.image,
      lat: node.coords.lat,
      lon: node.coords.lon,
      email: node.email,
      phone: node.phone,
      address: node.address,
    }))

  const mapConfig = {
    bootstrapURLKeys: {
      key: process.env.GATSBY_GOOGLE_API,
    },
    defaultCenter: {
      lat: 52.33,
      lng: 18.92,
    },
    defaultZoom: typeof window !== `undefined` && window.innerWidth < '767' ? 3 : 5,
    yesIWantToUseGoogleMapApiInternals: true,
  }

  return (
    <>
      <section css={sSectionWrapper}>
        <Headline
          h={2}
          title={content.title[lang]}
          desc={content.desc[lang]}
          large
          gradient
        />
        <div css={{ [s.md]: { padding: '1rem 0 4rem' } }}>
          <div css={sMapInner}>
            <GoogleMapReact {...mapConfig}>
              {links.map(({ lat, lon, link }, id) => (
                <LocationOmida key={id} lat={lat} lng={lon} link={link} />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </section>
    </>
  )
}

const sSectionWrapper = {
  maxWidth: 1500,
  margin: '0 auto',
  padding: '3rem 4rem',
  [s.md_1024]: {
    padding: '3rem 1rem 3rem',
  }
}

const sMapInner = {
  borderRadius: '1rem',
  overflow: 'hidden',
  marginTop: '2rem',

  backgroundColor: colors.greyLighter,
  [s.sm_down]: {
    width: '100%',
    height: 'calc(100vw - 4rem)',
  },
  [s.md]: {
    borderRadius: '2rem',
    height: 'calc(100vh - 8rem - 4rem)',
    position: 'relative',
  },
}

export default ProductMap
