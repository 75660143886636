import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1205.302"
    height="788.342"
    viewBox="0 0 1205.302 788.342">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.031"
        y1="1.072"
        x2="0.938"
        y2="-0.224"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#214a07" stopOpacity="0" />
        <stop offset="0.127" stopColor="#2d580f" stopOpacity="0.145" />
        <stop offset="0.43" stopColor="#72ab3f" />
        <stop offset="1" stopColor="#244803" stopOpacity="0.239" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.031"
        y1="1.072"
        x2="0.938"
        y2="-0.224"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#214a07" stopOpacity="0" />
        <stop offset="0.77" stopColor="#2d580f" stopOpacity="0.231" />
        <stop offset="0.971" stopColor="#72ab3f" />
        <stop offset="1" stopColor="#244803" stopOpacity="0.239" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="0.031"
        y1="1.072"
        x2="0.938"
        y2="-0.224"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#214a07" stopOpacity="0" />
        <stop offset="0.295" stopColor="#2d580f" stopOpacity="0.145" />
        <stop offset="1" stopColor="#72ab3f" stopOpacity="0.361" />
        <stop offset="1" stopColor="#244803" stopOpacity="0.239" />
      </linearGradient>
    </defs>
    <g
      id="Path_55"
      dataname="Path 55"
      transform="translate(7843.597 -10605.849)">
      <path
        id="Path_55-2"
        dataname="Path 55"
        d="M52.519,1006.95l-3.039-1.816L283.941,616.847a92.2,92.2,0,0,1,34.642-31.3,100.778,100.778,0,0,1,46.663-11.4H726.831c26.4,0,59.048-18.237,72.794-40.721L966.179,261.318c14.316-23.381,48.319-42.408,75.8-42.408h133.286v3.518H1041.982c-26.39,0-59.041,18.275-72.787,40.728l-166.561,272.1c-7.068,11.524-18.945,22-32.671,29.77-13.647,7.724-29.225,12.6-43.132,12.6H365.261c-31.914,0-61.906,15.721-78.265,41.032Z"
        transform="translate(-7892.883 10387.241)"
        opacity="0.43"
        fill="url(#linear-gradient)"
      />
      <g
        id="Path_55-3"
        dataname="Path 55"
        transform="translate(1.806 2.697)"
        opacity="0.43">
        <path
          id="Path_55-4"
          dataname="Path 55"
          d="M52.519,1006.95l-3.039-1.816L283.941,616.847a92.2,92.2,0,0,1,34.642-31.3,100.778,100.778,0,0,1,46.663-11.4H726.831c26.4,0,59.048-18.237,72.794-40.721L966.179,261.318c14.316-23.381,48.319-42.408,75.8-42.408h133.286v3.518H1041.982c-26.39,0-59.041,18.275-72.787,40.728l-166.561,272.1c-7.068,11.524-18.945,22-32.671,29.77-13.647,7.724-29.225,12.6-43.132,12.6H365.261c-31.914,0-61.906,15.721-78.265,41.032Z"
          transform="translate(-7894.883 10384.241)"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_56"
          dataname="Path 56"
          d="M853.821,349.454H233.579a125.659,125.659,0,0,1-65.2-18.134,119.6,119.6,0,0,1-45.179-48.841L29.52,124.247l3.454-2.038L126.727,280.57v.069c19.6,39.377,61.526,64.816,106.818,64.816H853.821a47.209,47.209,0,0,0,30.362-10.975l32.762-27.555a84.168,84.168,0,0,1,54.143-19.585h85.333c22.339,0,43.642-11.338,51.811-27.581l.078-.138L1229.367,60.07l3.454,2.072L1111.807,261.616c-4.577,9.05-12.59,16.675-23.177,22.046a72.415,72.415,0,0,1-32.192,7.677H971.087a80.126,80.126,0,0,0-51.561,18.635l-32.753,27.555a51.232,51.232,0,0,1-32.952,11.925Z"
          transform="translate(-7872.922 10579.015)"
          fill="url(#linear-gradient-3)"
        />
      </g>
      <path
        id="Path_56-2"
        dataname="Path 56"
        d="M853.821,349.454H233.579a125.659,125.659,0,0,1-65.2-18.134,119.6,119.6,0,0,1-45.179-48.841L90.552,228.45l3.454-2.038,32.72,54.158v.069c19.6,39.377,61.526,64.816,106.818,64.816H853.821a47.209,47.209,0,0,0,30.362-10.975l32.762-27.555a84.168,84.168,0,0,1,54.143-19.585h85.333c22.339,0,43.642-11.338,51.811-27.581l.078-.138L1229.367,60.07l3.454,2.072L1111.807,261.616c-4.577,9.05-12.59,16.675-23.177,22.046a72.415,72.415,0,0,1-32.192,7.677H971.087a80.126,80.126,0,0,0-51.561,18.635l-32.753,27.555a51.232,51.232,0,0,1-32.952,11.925Z"
        transform="translate(-7871.922 10581.015)"
        opacity="0.43"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
)
