import React from 'react'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import bricomarche from '../../images/partners/bricomarche.svg'
import infiniti from '../../images/partners/infiniti.svg'
import intermarche from '../../images/partners/intermarche.svg'
import michelin from '../../images/partners/michelin.svg'
import thyssenkrupp from '../../images/partners/thyssenkrupp.svg'
import { colors, s } from '../../style'

const partners = [michelin, infiniti, bricomarche, intermarche, thyssenkrupp]

const content = {
  title: {
    pl: 'Zaufali nam',
    en: 'They trusted us',
    zh: '专业值得信赖',
  },
  desc: {
    pl: `Poznaj naszych ekspertów.`,
    en: ``,
  },
}

const ProductPartners = () => {
  const { lang } = useLangContext()

  const logotypes = [
    {
      alt: 'Partner Bricomarche',
      image: bricomarche,
    },
    {
      alt: 'Partner Infiniti',
      image: infiniti,
    },
    {
      alt: 'Partner Michelin',
      image: michelin,
    },
    {
      alt: 'Partner Intermarche',
      image: intermarche,
    },
    {
      alt: 'Partner Thyssenkrupp',
      image: thyssenkrupp,
    },
  ]

  return (
    <>
      <section css={sSectionWrapper}>
        <Headline h={2} title={content.title[lang]} large gradient />
        <Swiper
          // install Swiper modules
          modules={[Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={2}
          slidesPerGroup={1}
          autoplay={{delay: 5000}}
          pagination={{ enabled: true, clickable: true }}
          breakpoints={{
            767: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              pagination: {
                enabled: true,
              },
            },
            1199: {
              slidesPerView: 4,
              slidesPerGroup: 1,
              pagination: {
                enabled: true,
              },
            },
          }}
          css={[productRelatedProducts]}
          style={{
            '--swiper-pagination-color': '#20214f',
          }}>
          {logotypes.map((item, index) => (
            <SwiperSlide key={index} >
              <div css={logoWrapper}>
                <img
                  key={index}
                  src={item.image}
                  css={sPartner}
                  loading='lazy'
                  alt={'Partner' + item.alt}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  )
}

const sSectionWrapper = {
  maxWidth: 1500,
  margin: '0 auto',
  padding: '3rem 4rem 5rem',
  [s.md_1024]: {
    padding: '3rem 1rem 3rem',
  },
}

const productRelatedProducts = {
  marginTop: '3rem',
}

const logoWrapper = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '200px',
  margin: '0 auto',
}

const sPartner = {
  width: '100%',
  height: 'auto',
  maxWidth: 300,

  opacity: .3,
}

export default ProductPartners
