import React from 'react'
export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1147.495'
    height='871.376'
    viewBox='0 0 1147.495 871.376'>
    <defs>
      <linearGradient
        id='linear-gradient'
        x1='0.971'
        y1='1.124'
        x2='0.062'
        y2='1.224'
        gradientUnits='objectBoundingBox'>
        <stop offset='0' stopColor='#214a07' stopOpacity='0' />
        <stop offset='0.445' stopColor='#2d580f' stopOpacity='0.145' />
        <stop offset='0.734' stopColor='#72ab3f' />
        <stop offset='1' stopColor='#244803' stopOpacity='0.239' />
      </linearGradient>
    </defs>
    <g id='Layer_2' data-name='Layer 2' opacity='0.42'>
      <g id='Warstwa_1' data-name='Warstwa 1'>
        <g id='Group_4' data-name='Group 4'>
          <path
            id='Path_8'
            data-name='Path 8'
            d='M1056.61,218.91l2.726,1.816L849.022,609.013a86.655,86.655,0,0,1-31.075,31.3,83.006,83.006,0,0,1-41.858,11.4H451.739c-23.679,0-52.968,18.237-65.3,40.721l-149.4,272.112c-12.841,23.381-43.344,42.408-68,42.408H49.48v-3.518H169.041c23.672,0,52.961-18.275,65.291-40.728L383.742,690.6c12.835-23.328,43.344-42.37,68-42.37H776.076c28.628,0,55.531-15.721,70.206-41.032Z'
            transform='translate(72.994 -218.91)'
            fill='url(#linear-gradient)'
          />
          <path
            id='Path_9'
            data-name='Path 9'
            d='M363.021,60.07H908.8a110.574,110.574,0,0,1,57.377,15.957A105.24,105.24,0,0,1,1005.93,119l82.429,139.236-3.039,1.793-82.5-139.35v-.061c-17.249-34.649-54.14-57.035-93.994-57.035H363.021A41.541,41.541,0,0,0,336.3,73.246L307.475,97.493a74.063,74.063,0,0,1-47.643,17.234H184.743c-19.657,0-38.4,9.977-45.591,24.27l-.068.122L32.559,314.713,29.52,312.89,136.006,137.363c4.027-7.963,11.079-14.673,20.394-19.4a63.722,63.722,0,0,1,28.327-6.755h75.1a70.507,70.507,0,0,0,45.371-16.4l28.821-24.247a45.082,45.082,0,0,1,29-10.494Z'
            transform='translate(59.136 511.018)'
            fill='url(#linear-gradient)'
          />
          <path
            id='Path_10'
            data-name='Path 10'
            d='M664.21,0h390.171c24.551,0,47.415,11.975,59.664,31.253l306.822,496.179-3,1.846L1111.05,33.092C1099.45,14.855,1077.73,3.5,1054.38,3.5H664.21Z'
            transform='translate(-639.712 342.098)'
            fill='url(#linear-gradient)'
          />
          <path
            id='Path_13'
            data-name='Path 13'
            d='M1095.57,172.321a62.156,62.156,0,0,0,53.243,30.615h216.673a62.308,62.308,0,0,1,55.872,34.657v.061l109.078,178.209-2.28,1.4L1419.02,238.945a59.774,59.774,0,0,0-53.52-33.335H1148.83a64.876,64.876,0,0,1-55.561-31.914L1018.45,46.8l2.28-1.36Z'
            transform='translate(-1018.45 419.587)'
            fill='url(#linear-gradient)'
          />
        </g>
      </g>
    </g>
  </svg>
)
