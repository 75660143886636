import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1132.334"
    height="871.376"
    viewBox="0 0 1132.334 871.376">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.029"
        y1="-0.124"
        x2="0.938"
        y2="-0.224"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#214a07" stopOpacity="0" />
        <stop offset="0.157" stopColor="#72ab3f" stopOpacity="0.678" />
        <stop offset="1" stopColor="#2d580f" stopOpacity="0.651" />
        <stop offset="1" stopColor="#244803" stopOpacity="0.239" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.029"
        y1="-0.124"
        x2="0.938"
        y2="-0.224"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#214a07" stopOpacity="0" />
        <stop offset="0.793" stopColor="#72ab3f" stopOpacity="0.102" />
        <stop offset="1" stopColor="#2d580f" stopOpacity="0.651" />
        <stop offset="1" stopColor="#244803" stopOpacity="0.239" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="0.029"
        y1="-0.124"
        x2="0.938"
        y2="-0.224"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#214a07" stopOpacity="0" />
        <stop offset="0.197" stopColor="#72ab3f" stopOpacity="0.678" />
        <stop offset="1" stopColor="#2d580f" stopOpacity="0.651" />
        <stop offset="1" stopColor="#244803" stopOpacity="0.239" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="0.029"
        y1="-0.124"
        x2="0.938"
        y2="-0.224"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#214a07" stopOpacity="0" />
        <stop offset="0.214" stopColor="#72ab3f" stopOpacity="0.678" />
        <stop offset="1" stopColor="#2d580f" stopOpacity="0.651" />
        <stop offset="1" stopColor="#244803" stopOpacity="0.239" />
      </linearGradient>
    </defs>
    <g
      id="Layer_2"
      dataname="Layer 2"
      transform="translate(-15.168)"
      opacity="0.99">
      <g id="Warstwa_1" dataname="Warstwa 1">
        <g id="Group_4" dataname="Group 4">
          <path
            id="Path_8"
            dataname="Path 8"
            d="M52.207,1006.95l-2.726-1.816L259.8,616.847a86.655,86.655,0,0,1,31.075-31.3,83.006,83.006,0,0,1,41.858-11.4H657.081c23.679,0,52.968-18.237,65.3-40.721l149.4-272.112c12.841-23.381,43.344-42.408,68-42.408H1059.34v3.518H939.779c-23.672,0-52.961,18.275-65.291,40.728l-149.409,272.1c-12.835,23.328-43.344,42.37-68,42.37H332.743c-28.628,0-55.531,15.721-70.206,41.032Z"
            transform="translate(-34.313 -135.574)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_9"
            dataname="Path 9"
            d="M754.864,314.713H209.082A110.574,110.574,0,0,1,151.7,298.756a105.24,105.24,0,0,1-39.756-42.978l-7.832-12.261,3.039-1.793,7.908,12.376v.061C132.306,288.81,169.2,311.2,209.051,311.2H754.864a41.541,41.541,0,0,0,26.717-9.658L810.41,277.3a74.064,74.064,0,0,1,47.643-17.234h75.089c19.657,0,38.4-9.977,45.591-24.27l.068-.122,106.524-175.6,3.039,1.824L981.878,237.421c-4.027,7.963-11.079,14.673-20.394,19.4a63.722,63.722,0,0,1-28.327,6.755h-75.1a70.507,70.507,0,0,0-45.371,16.4L783.86,304.22a45.082,45.082,0,0,1-29,10.494Z"
            transform="translate(-29.52 -14.425)"
            fill="url(#linear-gradient-2)"
          />
          <path
            id="Path_10"
            dataname="Path 10"
            d="M1420.87,529.278H1030.7c-24.551,0-47.415-11.975-59.664-31.253L664.21,1.846l3-1.846L974.026,496.186c11.6,18.237,33.32,29.589,56.67,29.589h390.171Z"
            transform="translate(-297.865)"
            fill="url(#linear-gradient-3)"
          />
          <path
            id="Path_13"
            dataname="Path 13"
            d="M1453.32,290.38a62.156,62.156,0,0,0-53.243-30.615H1183.4a62.308,62.308,0,0,1-55.872-34.657v-.061L1018.45,46.838l2.28-1.4,109.13,178.318a59.774,59.774,0,0,0,53.524,33.335h216.673A64.876,64.876,0,0,1,1455.62,289l74.816,126.9-2.28,1.36Z"
            transform="translate(-382.934 -10.912)"
            fill="url(#linear-gradient-4)"
          />
        </g>
      </g>
    </g>
  </svg>
)
